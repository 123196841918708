// TODO same fn in download-center. we can clean up after monorepo migration

// to get the preferred result, we need to use the given Intl.DisplayNames lang
// for certain i18next languages
export const languageAndLocaleMapping = new Proxy(
  {
    sr: 'sr-Latn-RS',
    'sr-RS': 'sr-Latn-RS',
  } as { [index: string]: string },
  {
    get(mapping, code: string) {
      if (code in mapping) {
        return mapping[code]
      }
      return code
    },
  },
)
